/* global fetch Headers window */
import { toQueryString, switchToDomain, } from '@haaretz/app-utils';
import UserFactory from '../user-factory-cookie-based';
import { mobileNumberParser, } from '../util/general-user-data-transform';
import createSiteConfig from '../site-config';

/**
 * A wrapper to a promise that limits it's resolution time
 * @export
 * @param {any} promise the promise to be resolved
 * @param {number} [timeout=5000] an optional timeout, in milliseconds
 * @param {string} [msgOnTimeout='operation timed out!'] an optional on timeout rejection message
 * @returns a promise that either resolves in the timeframe, or rejects on timeout.
 */
function withTimeout(
  promise,
  timeout = 8000,
  msgOnTimeout = 'לא הצלחנו לקבל תשובה מהשרת, אנא נסו שוב'
) {
  const timer = new Promise((resolve, reject) => setTimeout(() => {
    reject(new Error(msgOnTimeout));
  }, timeout)
  );
  return Promise.race([ promise, timer, ]);
}

const defaultTimeout = 8000;

/**
 * A promise that wraps an array of promises
 * @param promises an array of promises
 * @return {*} a promise that resolves when any of one of the promises in the array resolves,
 * and rejects only if all promises in the array reject
 */
function promiseAny(promises = []) {
  if (promises.length === 0) return Promise.resolve();
  return new Promise((resolve, reject) => {
    let rejectedCount = 0;
    promises.forEach(prm => (prm instanceof Promise
      ? prm
        .then(resolve)
        .catch(err => {
          rejectedCount += 1;
          if (rejectedCount === promises.length) reject(err);
        })
      : resolve(prm))
    );
  });
}

/**
 * User Services are the collective functions that are allowed communication with
 * our SSO backend server.
 * It bundles user services such as login, logout and register.
 * @param config { object } a configuration object to change the behavior of the user services.
 * @return {{login: login, logout: logout, register: register}}
 * @constructor optional: a plain object that is site configuration to override the services logic
 */
export default function UserService(config = {}) {
  const globalSiteConfig = createSiteConfig();
  const globalNewSsoDomain = getHost(globalSiteConfig.newSsoDomain, globalSiteConfig);
  // const globalNewSsoDomain = 'https://local.haaretz.co.il:8082';

  let plantCookiesCallback;
  if (config && config.plantCookiesCallback) {
    plantCookiesCallback = config.plantCookiesCallback;
  }

  /**
   * Check if a specific email is already registered.
   * @param {string} email the email of the user
   * @return {*} a promise that resolves to true iff the user exists
   */
  function checkEmailExists(email) {
    const siteConfig = createSiteConfig();
    const serviceUrl = `${siteConfig.newSsoDomain}/isuser?email=${email}`;
    const message = siteConfig.siteId === '85' ? 'An error has occurred, please try again.' : undefined;

    const checkEmailPromise = new Promise((resolve, reject) => fetch(serviceUrl).then(data => data
      .json()
      .then(json => resolve(json.success)
        // return reject(new Error('בדיקת דוא"ל נכשלה, נא לנסות שנית'));
      )
      .catch(err => {
        console.log('error from check email', err);
        const errorMessage = siteConfig.siteId === '85'
          ? 'An error has occurred, please try again.'
          : 'בדיקת דוא"ל נכשלה, נא לנסות שנית';

        return reject(new Error(errorMessage));
      })
    )
    );

    return withTimeout(checkEmailPromise, defaultTimeout, message);
  }

  /**
   * Check if a specific email is miniReg.
   * @param config { object } a configuration object to change the behavior of the user services.
   * @return {*} a promise that resolves to true if the user is miniReg
   */
  function checkIsMiniReg({ email, hostname, }) {
    /* SSR function can't extract window so we pass hostname */
    const siteConfig = createSiteConfig(hostname);
    const serviceUrl = `${siteConfig.newSsoDomain}/isminireg?email=${email ? encodeURIComponent(email) : ''}`;
    const message = siteConfig.siteId === '85' ? 'An error has occurred, please try again.' : undefined;

    const isMiniRegPromise = new Promise((resolve, reject) => fetch(serviceUrl).then(data => data
      .json()
      .then(json => resolve(json.success)
        // return reject(new Error('בדיקת דוא"ל נכשלה, נא לנסות שנית'));
      )
      .catch(err => {
        console.log('error from check isMiniReg', err);

        const errorMessage = siteConfig.siteId === '85'
          ? 'An error has occurred, please try again.'
          : 'לא הצלחנו לקבל תשובה מהשרת, אנא נסו שוב.';

        return reject(new Error(errorMessage));
      })
    )
    );

    return withTimeout(isMiniRegPromise, defaultTimeout, message);
  }

  /**
   * Sign in a user, using it's credentials. Will activate side effects
   * (in this case: planting cookies)
   * @param {object} { username, password, user, }
   * username: the username of the user (user email address)
   * password: the password of the user
   * user: the current user object (prior to login)
   * @return {*}
   */
  function login({
    username,
    password,
    user,
    trmsChk,
    readerId,
    subsNo,
  }) {
    const isEntitlement = subsNo ? typeof subsNo === 'string' : false;
    let anonymousId;
    if (!user || !user.anonymousId) {
      const factory = new UserFactory();
      anonymousId = factory.build().anonymousId;
    }
    else {
      anonymousId = user.anonymousId;
    }
    const siteConfig = createSiteConfig();
    const newSsoDomain = getHost(siteConfig.newSsoDomain, siteConfig);
    const serviceUrl = `${newSsoDomain}/${isEntitlement ? 'loginWithEntitlement' : 'loginUrlEncoded'}`;

    const params = 'newsso=true'
      + '&fromlogin=true'
      + '&layer=login'
      + `&site=${siteConfig.siteId}`
      + `&userName=${username}`
      + `&anonymousId=${anonymousId}`
      + `&password=${encodeURIComponent(password)}`
      + `&termsChk=${trmsChk ? 'on' : 'off'}`
      + `${readerId ? `&readerId=${readerId}` : ''}`
      + `${subsNo ? `&subsNo=${subsNo}` : ''}`;

    const message = siteConfig.siteId === '85' ? 'An error has occurred, please try again.' : undefined;

    const loginPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      body: params,
      credentials: 'include',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    })
      .then(parseResponse)
      .then(loginData => handleSsoResponse(loginData))
      .then(user => {
        resolve(user);
      })
      .catch(reason => reject(reason))
    );
    return withTimeout(loginPromise, defaultTimeout, message);
  }

  /**
   * Request a login without password from sso service, which initiates the first stage -
   * send email to the user containing a login link
   * @param {object} { email, trmsChk, site, returnTo}
   * email: the username of the user (user email address)
   * trmsChk: boolean from checkbox, did the user approve terms and conditions
   * site: site alias
   * returnTo: returnTo address
   * @return {*}
   */

  function requestLoginWithoutPassword({
    email,
    trmsChk,
    site,
    returnTo,
  }) {
    const serviceUrl = `${globalNewSsoDomain}/requestLoginWithoutPassword`;

    const params = `userName=${email}&termsChk=${trmsChk ? 'on' : 'off'}&site=${site}&isAbTest=true&returnTo=${returnTo}`;
    const message = globalSiteConfig.siteId === '85' ? 'An error has occurred, please try again.' : 'המערכת נתקלה בבעיה, אנא נסו שנית מאוחר יותר';

    const loginPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      body: params,
      credentials: 'include',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    })
      .then(data => data.json())
      .then(json => resolve(json))
      .catch(err => {
        const errorMessage = globalSiteConfig.siteId === '85'
          ? 'An error has occurred, please try again.'
          : 'המערכת נתקלה בבעיה, אנא נסו שנית מאוחר יותר';
        return reject(new Error(errorMessage));
      })
    );
    return withTimeout(loginPromise, defaultTimeout, message);
  }


  function requestLoginWithoutPasswordConfirmation({
    confirmationToken,
    email,
    site,
    termsCheck,
  }) {
    const serviceUrl = `${globalNewSsoDomain}/requestLoginWithoutPasswordConfirmation`;
    const termsCheckParam = termsCheck ? `&termsCheck=${termsCheck}` : '';
    const params = `confirmationToken=${encodeURIComponent(confirmationToken)}&userName=${email}&site=${site}${termsCheckParam}`;
    const message = globalSiteConfig.siteId === '85' ? 'An error has occurred, please try again.' : undefined;

    const loginPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      body: params,
      credentials: 'include',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    })
      .then(data => data.json())
      .then(json => resolve(json))
      .catch(err => {
        const errorMessage = globalSiteConfig.siteId === '85'
          ? 'An error has occurred, please try again.'
          : 'המערכת נתקלה בבעיה, אנא נסו שנית מאוחר יותר';
        return reject(new Error(errorMessage));
      })
    );
    return withTimeout(loginPromise, defaultTimeout, message);
  }

  /**
   * Sign in a user, using mobile number and OTP. Will activate side effects
   * (in this case: planting cookies)
   * @param {object} { mobile, otp, user, trmsChk, hash, }
   * mobile: mobile number
   * otp: one time password that was issued to the user
   * trmsChk: the value of the checkbox that verifies the users agreement to
   * promotional mail sending
   * hash: the hash that was issued after the generation of the OTP
   * user: the current user object (prior to login)
   * @return {*}
   */
  function loginWithMobile({ mobile, email, otp, trmsChk, hash, user, phonePrefix, readerId, }) {
    let anonymousId;
    const { prefix, suffix, } = mobileNumberParser(mobile, phonePrefix);
    if (!user) {
      const factory = new UserFactory();
      anonymousId = factory.build().anonymousId;
    }
    else {
      anonymousId = user.anonymousId;
    }
    const siteConfig = createSiteConfig();
    const newSsoDomain = getHost(siteConfig.newSsoDomain, siteConfig);
    const serviceUrl = `${newSsoDomain}/loginWMobile`;
    const params = {
      anonymousId,
      otp,
      site: siteConfig.siteId,
      ...(suffix ? {
        mobilePrefix: prefix,
        mobileNumber: suffix,
      } : {}),
      email,
      id: hash,
      termsChk: trmsChk ? 'on' : 'off',
      readerId,
    };
    const message = siteConfig.siteId === '85' ? 'An error has occurred, please try again.' : undefined;

    const loginPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then(parseResponse)
      .then(loginData => handleSsoResponse(loginData))
      .then(() => {
        resolve();
      })
      .catch(reason => reject(reason)));

    return withTimeout(loginPromise, defaultTimeout, message);
  }

  /**
   * Logout a user from it's current session
   * @param {any} user the currently logged in user
   */
  function logout({ user, } = {}) {
    const factory = new UserFactory();
    const siteConfig = createSiteConfig();
    const message = siteConfig.siteId === '85' ? 'An error has occurred, please try again.' : undefined;

    const newSsoDomain = getHost(siteConfig.newSsoDomain, siteConfig);
    const serviceUrl = `${newSsoDomain}/logout`;
    const builtUser = user || factory.build();
    const params = `mobile=false&anonymousId=${builtUser.anonymousId}`;
    const logoutPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      body: params,
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    })
      .then(parseResponse)
      .then(logoutData => handleSsoResponse(logoutData))
      .then(() => {
        resolve();
      })
      .catch(reason => reject(reason)));

    return withTimeout(logoutPromise, defaultTimeout, message);
  }

  /**
   * Register a new user
   * This method works with raw data passed as arguments, and not FormData
   * @param {Object} formData
   * @param {string} formData.userName the email of the new user
   * @param {string} formData.password the password of the new user
   * @param {string} formData.confirmPassword the password of the new user, again, for validation
   * @param {string} formData.firstName the user first name
   * @param {string} formData.lastName the user last name
   * @param {string} formData.mobilePrefix the first part of the user mobile number
   * @param {string} formData.mobileNumber the second part of the user mobile number
   * @param {boolean} formData.termsChk whether or not the user has checked the terms of service agreement
   * @param {string} formData.gRecaptchaResponse google's captcha response to bot detection
   *
   * @return {Promise} A promise that resolves when the operation is complete
   */
  function register({
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    mobilePrefix,
    mobileNumber,
    termsChk,
    gRecaptchaResponse,
    restEntryType,
    user,
    readerId,
    subsNo,
    isEntitlement,
  }) {
    let anonymousId;
    if (!user) {
      const factory = new UserFactory();
      anonymousId = factory.build().anonymousId;
    }
    else {
      anonymousId = user.anonymousId;
    }
    const siteConfig = createSiteConfig();
    const message = siteConfig.siteId === '85' ? 'An error has occurred, please try again.' : undefined;
    const newSsoDomain = getHost(siteConfig.newSsoDomain, siteConfig);
    const serviceUrl = `${newSsoDomain}/${isEntitlement ? 'registerWithEntitlement' : 'registerUrlEncoded'}`;

    const params = toQueryString({
      newsso: true,
      layer: 'createuser',
      site: siteConfig.siteId,
      userName: email,
      password: encodeURIComponent(password),
      confirmPassword: encodeURIComponent(confirmPassword),
      firstName,
      lastName,
      mobilePrefix,
      mobileNumber,
      termsChk: termsChk ? 'on' : 'off',
      anonymousId,
      'g-recaptcha-response': gRecaptchaResponse,
      restEntryType,
      readerId,
      subsNo,
    });

    const registerPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      body: params,
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    })
      .then(parseResponse)
      .then(registerData => handleSsoResponse(registerData))
      .then(() => {
        resolve();
      })
      .catch(reason => reject(reason)));

    return withTimeout(registerPromise, defaultTimeout, message);
  }

  /**
   * Update user
   * This method works with raw data passed as arguments, and not FormData
   * @param {Object} formData
   * @param {string} formData.userName the email of the new user
   * @param {string} formData.password the password of the new user
   * @param {string} formData.firstName the user first name
   * @param {string} formData.lastName the user last name
   *
   * @return {Promise} A promise that resolves when the operation is complete
   */
  function updateUserMinireg({
    email,
    password,
    firstName,
    lastName,
  }) {
    const siteConfig = createSiteConfig();
    const message = siteConfig.siteId === '85' ? 'There was no answer from our service.' : undefined;
    const newSsoDomain = getHost(siteConfig.newSsoDomain, siteConfig);
    const serviceUrl = `${newSsoDomain}/updateUserData`;
    const params = {
      userName: email,
      site: siteConfig.siteId,
      password: encodeURIComponent(password),
      firstName,
      lastName,
    };

    const updateUserPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      body: JSON.stringify(params),
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(parseResponse)
      .then(updatedData => handleSsoResponse(updatedData))
      .then(() => {
        resolve();
      })
      .catch(reason => reject(reason))
    );
    return withTimeout(updateUserPromise, defaultTimeout, message);
  }

  /**
   * Update profile
   * This method works with raw data passed as arguments, and not FormData
   * @param {Object} formData
   * @param {string} formData.email the email of the user
   * @param {string} formData.firstName the firstName of the user
   * @param {string} formData.lastName the lastName of the user
   * @param {string} formData.phonePrefix the phonePrefix of the user
   * @param {string} formData.phoneSuffix the phoneSuffix of the user
   *
   * @return {Promise} A promise that resolves when the operation is complete
   */
  function updateUserProfile({
    email,
    firstName,
    lastName,
    mobilePrefix,
    mobileNumber,
  }) {
    const siteConfig = createSiteConfig();
    const message = siteConfig.siteId === '85' ? 'There was no answer from our service.' : undefined;
    const newSsoDomain = getHost(siteConfig.newSsoDomain, siteConfig);
    const serviceUrl = `${newSsoDomain}/updateUserProfile`;
    const params = {
      userName: email,
      site: siteConfig.siteId,
      firstName: (firstName || '').trim(),
      lastName: (lastName || '').trim(),
      mobilePrefix,
      mobileNumber,
    };

    const updateUserPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(parseResponse)
      .then(updatedData => handleSsoResponse(updatedData))
      .then(() => {
        resolve();
      })
      .catch(reason => reject(reason))
    );
    return withTimeout(updateUserPromise, defaultTimeout, message);
  }

  /**
   * Update password
   * This method works with raw data passed as arguments, and not FormData
   * @param {Object} formData
   * @param {string} formData.email the email of the user
   * @param {string} formData.currentPassword the current password of the user
   * @param {string} formData.newPassword` the new password of the  user
   * @param {string} formData.newPasswordConfirm the confirmation new password of the user
   *
   * @return {Promise} A promise that resolves when the operation is complete
   */
  function updatePassword({
    email,
    currentPassword,
    newPassword,
    newPasswordConfirm,
  }) {
    const siteConfig = createSiteConfig();
    const message = siteConfig.siteId === '85' ? 'There was no answer from our service.' : undefined;
    const newSsoDomain = getHost(siteConfig.newSsoDomain, siteConfig);
    const serviceUrl = `${newSsoDomain}/updatePassword`;
    const params = {
      userMail: email,
      site: siteConfig.siteId,
      oldPassword: currentPassword,
      password: newPassword,
      confirm: newPasswordConfirm,
    };

    const updateUserPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(parseResponse)
      .then(updatedData => handleSsoResponse(updatedData))
      .then(() => {
        resolve();
      })
      .catch(reason => reject(reason))
    );
    return withTimeout(updateUserPromise, defaultTimeout, message);
  }


  /**
   * Update email
   * This method works with raw data passed as arguments, and not FormData
   * @param {Object} formData
   * @param {string} formData.email the current email of the user
   * @param {string} formData.newEmail the new email of the user
   *
   * @return {Promise} A promise that resolves when the operation is complete
   */
  function updateEmail({
    email,
    newEmail,
  }) {
    const siteConfig = createSiteConfig();
    const message = siteConfig.siteId === '85' ? 'There was no answer from our service.' : undefined;
    const newSsoDomain = getHost(siteConfig.newSsoDomain, siteConfig);
    const serviceUrl = `${newSsoDomain}/updateUserMail`;
    const params = {
      oldUserName: email,
      site: siteConfig.siteId,
      userName: (newEmail || '').trim(),
    };

    const updateUserPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(parseResponse)
      .then(updatedData => handleSsoResponse(updatedData))
      .then(() => {
        resolve();
      })
      .catch(reason => reject(reason))
    );
    return withTimeout(updateUserPromise, defaultTimeout, message);
  }

  /**
   * Cancelletion subscription
   * This method works with raw data passed as arguments, and not FormData
   * @param {Object} formData
   * @param {string} formData.productId the id of the product
   * @param {string} formData.reasonCode the id of the reason
   *
   * @return {Promise} A promise that resolves when the operation is complete
   */
  function cancelSubscription({
    productId,
    reasonCode,
  }) {
    const siteConfig = createSiteConfig();
    const message = siteConfig.siteId === '85' ? 'There was no answer from our service.' : undefined;
    const purchaseDomain = getHost(siteConfig.purchaseDomain, siteConfig);
    const serviceUrl = `${purchaseDomain}/cancelSubscription?productId=${productId}&reasonCode=${reasonCode}`;

    const cancelSubscriptionPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'GET',
      credentials: 'include',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (!data.success) {
          return reject(data.msg);
        }

        return resolve(data);
      })
      .catch(reason => reject(reason))
    );
    return withTimeout(cancelSubscriptionPromise, defaultTimeout, message);
  }

  /**
   * Accept reset password user
   * This method works with raw data passed as arguments, and not FormData
   * @param {Object} formData
   * @param {string} formData.confirmation the confirmation token
   * @param {string} formData.password the new password
   * @param {string} formData.confirm the password confitmation
   * @param {string} formData.userMail the email of the user
   *
   * @return {Promise} A promise that resolves when the operation is complete
   */
  function acceptResetPassword({
    confirmation,
    password,
    confirm,
    userMail,
  }) {
    const siteConfig = createSiteConfig();
    const message = siteConfig.siteId === '85' ? 'There was no answer from our service.' : undefined;
    const newSsoDomain = getHost(siteConfig.newSsoDomain, siteConfig);
    const serviceUrl = `${newSsoDomain}/acceptResetPassword`;
    const params = {
      confirmation,
      password,
      confirm,
      userMail,
    };

    const acceptResetPasswordPromise = new Promise((resolve, reject) => fetch(serviceUrl, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(params),
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then(parseResponse)
      .then(updatedData => handleSsoResponse(updatedData))
      .then(data => resolve(data))
      .catch(reason => reject(reason))
    );
    return withTimeout(acceptResetPasswordPromise, defaultTimeout, message);
  }

  /**
   * Parses an SSO response to a login request and converts it to an actionable object
   * @param response the response received from an SSO login request
   * @return {*} a promise that resolves a parsed object element of the following form:
   * {status: 'success'|'failure' , data: {i:*,}, message}
   */
  function parseResponse(response) {
    return response.json().then(
      data => new Promise(resolve => {
        const status = data['login-status'];
        const message = data.message || data.error || data.msg;
        const isAbuse = status === false && message && message[0] === 'abuse-logout';
        let redirectInfo = null;

        if (isAbuse) {
          const token = data.user && data.user.abuse && data.user.abuse.redirect;
          const redirect = typeof token === 'string'
            ? /(\/abuse):(.*):(.*)/.exec(token)
            : null;
          const siteConfig = createSiteConfig();

          const hostname = getHost('https://login.haaretz.co.il', siteConfig);

          redirectInfo = redirect
            ? {
              url: `${hostname}${redirect[1]}`,
              email: redirect[2],
              sso: redirect[3],
            }
            : null;
        }

        resolve({
          ...data,
          status,
          message,
          redirectInfo,
        });
      })
    );
  }

  /**
   * Handle a parsed SSO response payload
   * @param serviceData an object element of the following form:
   * {status: 'success'|'failure'|'error' , data: {i:*,} | 'nothing', message}
   * @return {*} a promise that resolves iff:
   * 1) The first param is a successfully parsed success response from an SSO login endpoint
   * 2) All of the side effect operations that happen as a result of the response,
   * have completed successfully.
   * if the promise rejects, it rejects with an error object that have the sso error message
   */
  function handleSsoResponse(serviceData) {
    let promise = null;

    if (serviceData && (serviceData.success || serviceData.status === 'success')) {
      const cookiesPromises = [];

      if (plantCookiesCallback) {
        const promiseFromCallback = plantCookiesCallback();
        cookiesPromises.push(promiseFromCallback);
      }
      promise = promiseAny(cookiesPromises);
    }
    else if (serviceData.redirectInfo) {
      // eslint-disable-next-line no-undef
      window.location = `${serviceData.redirectInfo.url}?loginData=${btoa(JSON.stringify(serviceData.redirectInfo))}`;
    }
    else {
      const siteConfig = createSiteConfig();
      const errorMessage = siteConfig.siteId === '85' ? 'An error has occurred.' : 'שגיאה במערכת המנויים';
      const errorOptions = serviceData.cause || serviceData.messageCode?.[0] ? { cause: { code: serviceData.cause, msg: serviceData.messageCode?.[0], }, } : null;

      promise = Promise.reject(
        new Error(serviceData.message || errorMessage, errorOptions)
      );
    }

    return promise;
  }

  // eslint-disable-next-line no-shadow
  function getHost(domain, config) {
    if (!domain || !domain.startsWith('http')) return domain;

    const host = config.siteId === '10' ? 'login.themarker.com' : config.siteId === '85' ? 'login.haaretz.com' : 'login.haaretz.co.il';
    return switchToDomain(host, domain);
  }

  /**
   * Public API for the UserServices
   */
  return {
    checkEmailExists,
    checkIsMiniReg,
    login,
    requestLoginWithoutPassword,
    requestLoginWithoutPasswordConfirmation,
    loginWithMobile,
    logout,
    register,
    updateUserMinireg,
    updateUserProfile,
    acceptResetPassword,
    cancelSubscription,
    updatePassword,
    updateEmail,
    createSiteConfig,
    getHost,
  };
}
