// @flow
import React from 'react';
import type { Node, ChildrenArray, } from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, borderBottom, } from '@haaretz/htz-css-tools';
import { H, } from '@haaretz/htz-components';

type Props = {
  title?: string | ChildrenArray<Node>,
  miscStyles?: ?Object,
  children?: ?ChildrenArray<Node> | Node,
  showBanner?: boolean,
  isCrypto?: boolean,
  isCommercial?: boolean,
};

function RowItem({ title, children, miscStyles, showBanner, isCrypto, isCommercial, }: Props): Node {
  const { css, theme, } = useFela();
  return (
    <>
      {title && (
        <div
          className={css({
            ...theme.type(1),
            color: isCommercial ? theme.color('list', 'listViewHeaderCommercial') : theme.color('neutral', '-1'),
            display: 'flex',
            alignItems: 'flex-end',
            ...(isCommercial ? { justifyContent: 'space-between', } : {}),
            extend: [
              isCommercial
                ? borderBottom('2px', 1, 'solid', theme.color('list', 'listViewHeaderCommercial'))
                : borderBottom('2px', 1, 'solid', theme.color('neutral', '-1')),
              ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
            ],
          })}
        >
          <H
            className={showBanner
              ? css({ marginInlineEnd: '2rem', })
              : null}
          >
            {title}
          </H>
          {isCommercial ? (
            <div
              className={css({
                ...theme.type(-1),
                color: theme.color('neutral', '-1'),
              })}
            >
              תוכן שיווקי
            </div>
          ) : null}
          {showBanner ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.as-invest.co.il/%D7%90%D7%9C%D7%98%D7%A9%D7%95%D7%9C%D7%A8-%D7%A9%D7%97%D7%9D-%D7%94%D7%95%D7%A8%D7%99%D7%96%D7%95%D7%9F/?utm_source=the_marker&utm_medium=button&utm_campaign=horizon_may21"
              className={css({
                marginInlineStart: 'auto',
                maxWidth: '50vw',
              })}
            >
              <img
                src="/static/images/crypto_banner_button.png"
                alt="crypto-banner"
                className={css({ width: '100%', })}
              />
            </a>
          ) : null}
        </div>
      )}
      {children}
    </>
  );
}

RowItem.defaultProps = { miscStyles: null, children: null, title: null, showBanner: false, isCrypto: false, isCommercial: false, };
export default RowItem;
