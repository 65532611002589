/* global window localStorage fetch */
// @flow
import React from 'react';
import gql from 'graphql-tag';
import config from 'config';

import { useInView, } from 'react-intersection-observer';
import { useRouter, } from 'next/router';
import { useLazyQuery, useApolloClient, } from 'react-apollo';
import type { ComponentType, Node, } from 'react';
import type { DocumentNode, } from 'graphql/language/ast';

import Query from '../ApolloBoundary/Query';
import EventTracker from '../../utils/EventTracker';
import type { ListBiActionType, } from '../../flowTypes/ListBiActionType';
import type { ListDataType, } from '../../flowTypes/ListDataType';
import ListPlaceholder from '../ListPlaceholder/ListPlaceholder';
import UserDispenser, { useUser, } from '../User/UserDispenser';
import useMainContentIdData from '../../hooks/Page/useMainContentIdData';
import useTagsData from '../../hooks/Page/useTagsData';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';
import { writeToSessionStorage, } from '../../utils/writeToStorage';

import Debug from '../Debug/Debug';
import UPDATE_LIST from './mutations/updatePersonalList.graphql';
import useOneTime from '../../hooks/useOneTime';
import { listAdapter, } from './ListAdapters';

// import LIST_VARIABLES_QUERY from './queries/getListVariables.graphql';

const siteConfigPersonalization = config.has('usePersonalization') && config.get('usePersonalization');

const GET_SECTION: DocumentNode = gql`
  query GetSection {
    articleSection @client {
      url
    }
  }
`;

const getLocalStorageItem = key => {
  if (typeof window !== 'undefined' && window.localStorage) {
    try {
      return window.localStorage.getItem(key);
    }
    catch (err) {
      console.error(err);
      return null;
    }
  }
  return null;
};

const writeToSessionStorageIfExist = (key, value) => {
  if (value) {
    writeToSessionStorage({
      key,
      value,
      errorMessage: `error writing ${key} to session storage`,
    });
  }
};

type UserTypesFilterProps = {
  userTypes: ?(string[]),
  children: Node,
};

const defaultUser = { user: { type: 'anonymous', }, };
function UserTypesFilter({ userTypes, children, }: UserTypesFilterProps): Node {
  if (Array.isArray(userTypes) && userTypes.length > 0) {
    return (
      <UserDispenser
        render={({ user, }) => {
          const userType = (user || defaultUser.user).type || defaultUser.user.type;
          const shouldRender = userTypes.some(type => (type === userType || (type === 'payer' && userType === 'paying')));
          return shouldRender ? children : null;
        }}
      />
    );
  }

  return children;
}

const observerSettings = { threshold: 0.5, triggerOnce: true, };

type ListComponentProps = {
  // generalized this to avoid complexity of dfp/teaser/clicktracker
  List: Object,
  listId: string,
  gaAction: () => void,
  biAction: ?ListBiActionType,
  isLazyloadImages: boolean,
};
export type ListContentRendererProps = {
  children: ListComponentProps => Node,
  updateListDuplication: Function,
  view: string,
  isPersonal: boolean,
  viewProps: Object,
  listData: ListDataType,
  personalContentId: string,
};

ListContentRenderer.defaultProps = {
  isPersonal: false,
  viewProps: {},
  personalContentId: null,
};

function ListContentRenderer({
  children,
  updateListDuplication,
  view,
  isPersonal,
  viewProps,
  listData,
  personalContentId,
}: ListContentRendererProps): Node {
  const [ ref, inView, ] = useInView(observerSettings);
  const { user, } = useUser();
  const refChild = React.useMemo(() => divRef => divRef?.firstChild && ref(divRef?.firstChild), [ ref, ]);
  const TeaserRepresentedContentsId = listData?.items?.reduce(
    (acc, currentVal) => `${acc ? `${acc},` : ''}${currentVal.representedContent || currentVal.contentId}`,
    ''
  );
  const client = useApolloClient();

  useOneTime(!!user.email && inView && siteConfigPersonalization && TeaserRepresentedContentsId, () => client.mutate({
    mutation: UPDATE_LIST,
    variables: {
      input: {
        viewedArticleIds: TeaserRepresentedContentsId || '',
        // In ArticleObserver.js we use the articleId as the readArticleId
        // to update the readArticleId one time each Article page
        readArticleId: null,
      },
    },
  })
  );
  if (!listData) {
    return <Debug>List has not data</Debug>;
  }

  const isSsrlList = listData && listData.loadPriority === 'ssr';
  const {
    title,
    items,
    contentId,
    isLazyloadImages,
    isAddContentIdsToGlobalSet = true,
    testGroup,
    testName,
    testGroupLabel,
    ...restList
  } = listData;

  !isPersonal
    && items?.length
    && isAddContentIdsToGlobalSet
    && typeof updateListDuplication === 'function'
    && updateListDuplication(items);

  return (
    <EventTracker>
      {({ biAction, biImpression, gaAction, HtzReactGA, }) => {
        const clickAction = ({ index, data, } = {}) => {
          if (data && (data.teaserId || data.contentId)) {
            // check if the list is personal and if so, write the origin and the articleId to session storage
            if (data.origin) {
              writeToSessionStorage({
                key: 'listOrigin',
                value: data.origin,
                errorMessage: 'error writing recommendation type to session storage ',
              });
              writeToSessionStorage({
                key: 'articleId',
                value: data.contentId,
                errorMessage: 'error writing teaser article id to session storage ',
              });
            }

            // Write to session storage if these keys exist adn the list is personal
            if (testGroup || testName || testGroupLabel) {
              writeToSessionStorageIfExist('ab_test_group', testGroup);
              writeToSessionStorageIfExist('ab_test_name', testName);
              writeToSessionStorageIfExist('ab_test_group_label', testGroupLabel);
            }

            data && (data.teaserId || data.contentId) && biAction({
              ...(listData?.view === 'Scruffy' || view === 'Scruffy'
                ? {
                  actionCode: 153,
                  feature: 'Web Games List',
                  featureType: 'Content',
                  campaignName: data.campaignName,
                }
                : {
                  actionCode: 109,
                }),
              ListId: contentId || personalContentId,
              ListName: title || null,
              NoInList: index + 1,
              ViewName: view || listData?.view,
              ...((listData?.view === 'Schoonhoven' || view === 'Schoonhoven')
                && index === 0
                ? { feature: 'Player in teaser', }
                : {}),

              ...(data && data.contentId
                ? { nextArticleId: data.contentId, }
                : {}),
              ...(data && data.teaserId
                ? { nextArticleTeaserId: data.teaserId, }
                : {}),
              ...(data && data.origin ? { origin: data.origin, } : {}),
              ...(!isSsrlList && isPersonal
                ? { personalFeature: true, }
                : { personalFeature: false, }),
              ...(testGroup ? { abGroup: testGroup, } : {}),
              ...(testName ? { abTestName: testName, } : {}),
              ...(testGroupLabel ? { abTestGroupLabel: testGroupLabel, } : {}),
            });
          }
        };

        const articleListBiImpression = ({ index, data, } = {}) => biImpression
          && data
          && (data.teaserId || data.contentId)
          && biImpression({
            ListId: contentId || personalContentId,
            ListName: title || null,
            NoInList: index + 1,
            ViewName: view || listData?.view,
            ...((listData?.view === 'Schoonhoven' || view === 'Schoonhoven')
              && index === 0
              ? { feature: 'Player in teaser', }
              : {}),
            ...(data && data.origin ? { origin: data.origin, } : {}),

            ...(data && data.contentId
              ? { nextArticleId: data.contentId, }
              : {}),
            ...(data && data.teaserId
              ? { nextArticleTeaserId: data.teaserId, }
              : {}),
            ...(!isSsrlList && isPersonal
              ? { personalFeature: true, }
              : { personalFeature: false, }),
            ...(testGroup ? { abGroup: testGroup, } : {}),
            ...(testName ? { abTestName: testName, } : {}),
            ...(testGroupLabel ? { abTestGroupLabel: testGroupLabel, } : {}),
          });

        const list = { items, title, ...restList, };
        const { userTypes, } = list || {};

        return (
          <div style={{ display: 'contents', }} ref={refChild}>
            <UserTypesFilter userTypes={userTypes}>
              {children({
                list,
                listId: contentId,
                gaAction,
                biAction: clickAction,
                biImpression: articleListBiImpression,
                isLazyloadImages,
                inView,
                ...viewProps,
              })}
            </UserTypesFilter>
          </div>
        );
      }}
    </EventTracker>
  );
}


type ListDataGetterProps = ListContentRendererProps & {
  query: DocumentNode,
  variables: Object,
  router: Object,
  Placeholder: ComponentType<any>,
};

type ListRendererProps = ListDataGetterProps & {
  section: string,
  hasListData: boolean,
};

const PersonalListSrvUrl = `${config.get('service.brightspot')}/srv/personalization/get-personal-list`;


function ListRenderer({
  listData,
  variables,
  query,
  children,
  section,
  Placeholder,
  isPersonal,
  ...restOfProps
}: ListRendererProps): Node {
  const mainContentId = useMainContentIdData();
  const breadCrumbs = useBreadcrumbsData();
  const tags = useTagsData();
  const { history, getHistory, } = variables || {};
  const historyTeaserIds = typeof getHistory === 'function' ? getHistory() : history;

  const defaultPersonalListData = isPersonal && listData?.items?.length > 0 ? listData : null;

  const [ personalData, setPersonalData, ] = React.useState(defaultPersonalListData);
  const [ personalLoading, setPersonalLoading, ] = React.useState(isPersonal && !personalData);

  // Lazy load query when personal list is not found
  const [ loadClient, { data: clientData, called: clientCalled, }, ] = useLazyQuery(query, {
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      id: listData.contentId,
      mainContentId,
      exclude:
        Array.isArray(historyTeaserIds) && historyTeaserIds.length
          ? historyTeaserIds.join(',')
          : undefined,
    },
  });

  const skipPersonal = clientCalled || !isPersonal || !!personalData;

  // This code retrieves values from local storage to exclude specific IDs
  // from homepage lists for personalization data.
  const excludedIds = [];
  const krokerExclude = getLocalStorageItem('KrokerExclude');
  const boxyExclude = getLocalStorageItem('boxyExclude');
  const spotExclude = getLocalStorageItem('spotExclude');
  // homepage list exclude
  if (krokerExclude && !variables.articleId) {
    excludedIds.push(krokerExclude);
  }
  // homepage list exclude
  if (boxyExclude && !variables.articleId) {
    excludedIds.push(boxyExclude);
  }
  // article page exclude
  if (spotExclude && !!variables.articleId) {
    excludedIds.push(spotExclude);
  }

  // This code creates a string that contains the excluded IDs retrieved from local storage
  // separated by a comma and a space.
  const ListItemsIdsExcluded = excludedIds.length > 0 ? excludedIds.join(', ') : undefined;

  // map tags content ids
  const tagsContentIds = Array.isArray(tags) && tags.map(item => item.contentId).join(', ');
  // get section id from breadcrumbs
  const sectionId = Array.isArray(breadCrumbs) && breadCrumbs.slice(-2, -1)[0]?.contentId;

  // Personal list query
  useOneTime(!skipPersonal, async () => {
    const args = {
      ...variables,
      // we add the last article id to the query
      // so homepage will be updated when user read an article
      articleId: variables.articleId || getLocalStorageItem('lastArticleId'),
      ...(ListItemsIdsExcluded
        ? { exclude: typeof ListItemsIdsExcluded === 'string' ? ListItemsIdsExcluded : '', }
        : {}),
      ...variables.articleId ? { sectionId, tagIds: tagsContentIds, } : {},

    };

    let url = PersonalListSrvUrl;


    if (args.listId !== null && args.listId !== undefined) {
      url += `?listId=${encodeURIComponent(args.listId)}`;
    }

    try {
      setPersonalLoading(true);
      const res = await fetch(url, { method: 'POST', credentials: 'include', body: JSON.stringify(args), });

      const resData = await res.json();

      const data = listAdapter(resData);

      setPersonalData(data);

      const hasItems = !!data?.items?.length;

      if (!hasItems && !clientCalled) {
        loadClient();
      }
    }
    catch (error) {
      console.log('Personal list data getter', error);

      if (!clientCalled) {
        loadClient();
      }
    }
    finally {
      setPersonalLoading(false);
    }
  });

  useOneTime(!isPersonal && !clientCalled, () => {
    loadClient();
  });

  const listProps = {
    ...restOfProps,
    ...(isPersonal
      ? {
        dedupeReadingHistory: false,
        personalListId: variables.listId,
        isPersonal: true,
      }
      : {}),
  };

  if (personalLoading) return null;

  return (
    <ListContentRenderer
      listData={(isPersonal && personalData) || clientData?.List}
      {...(isPersonal ? { personalContentId: listData.contentId, } : {})}
      section={section}
      {...listProps}
      isPersonal={isPersonal && !!personalData}
    >
      {children}
    </ListContentRenderer>
  );
}

ListDataGetter.section = null;

ListDataGetter.defaultProps = {
  isPersonal: false,
  Placeholder: ListPlaceholder,
};
function ListDataGetter(props: ListDataGetterProps): Node {
  const router = useRouter();
  const { Placeholder, children, listData, viewProps, isPersonal, } = props;

  const isSsr = listData && listData.loadPriority === 'ssr';
  const hasListData = isSsr || listData?.items?.length > 0; // listData.isExpanded //TODO: not exits;
  // When we have listData, we can render the list immediately by skipping the <ListRenderer /> Query
  if (!isPersonal && hasListData) {
    const section = hasListData ? router.asPath : ListDataGetter.section || '/';
    return (
      <ListContentRenderer
        listData={listData}
        section={section || '/'}
        viewProps={viewProps || {}}
        {...props}
      >
        {children}
      </ListContentRenderer>
    );
  }

  return (
    <Query query={GET_SECTION}>
      {({ data: sectionData, loading, error, }) => {
        if (loading) return null;
        if (error) return null;
        const { url, } = (sectionData || {}).articleSection || {};
        ListDataGetter.section = url || '/';

        return (
          <ListRenderer
            {...props}
            viewProps={viewProps || {}}
            listData={listData}
            section={ListDataGetter.section}
            Placeholder={Placeholder}
            isPersonal={isPersonal}
          >
            {children}
          </ListRenderer>
        );
      }}
    </Query>
  );
}

export default ListDataGetter;
