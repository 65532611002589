// @flow
/* global document */
import { useEffect, useState, } from 'react';

const NETWORK_CODE = '9401';
const ADUNIT_PLACEHOLDER = '{adUnitCode}';

const MainContentType = {
  section: 'section',
  homepage: 'homepage',
  other: 'other',
};

/**
 * creates an ad-unit-path template for ad units on current page.
 */
export default function useAdUnitPathTemplate() {
  const [ adUnitPathTemplate, setAdUnitPathTemplate, ] = useState(ADUNIT_PLACEHOLDER);

  useEffect(() => {
    const url = new URL(document.URL);
    const sectionPathSegments = url.pathname.split('/').filter(p => !!p);
    const path = [];

    const mainContentType = sectionPathSegments.length === 0
      ? MainContentType.homepage
      : MainContentType.section;

    let currentAdUnitPathSegment = ADUNIT_PLACEHOLDER;

    path.push(`/${NETWORK_CODE}`);
    path.push('finance.themarker.com');
    path.push(ADUNIT_PLACEHOLDER);

    currentAdUnitPathSegment += mainContentType === (MainContentType.homepage) ? '_homepage' : '_section';
    path.push(currentAdUnitPathSegment);


    for (const pathSegment of sectionPathSegments) {
      currentAdUnitPathSegment += `.${pathSegment}`;
      path.push(currentAdUnitPathSegment);
    }

    setAdUnitPathTemplate(path.join('/'));
  }, []);

  return adUnitPathTemplate;
}

export function createAdSlotProps(adUnitConfig, adUnitPathTemplate) {
  const props = {
    adUnitPath: createAdSlotPath(adUnitPathTemplate, adUnitConfig.adUnitCode),
    ...adUnitConfig,
  };

  return props;
}

export function createAdSlotPath(adUnitPathTemplate, adUnitCode) {
  if (typeof adUnitPathTemplate !== 'string') return adUnitPathTemplate;

  return adUnitPathTemplate.replaceAll(ADUNIT_PLACEHOLDER, adUnitCode);
}
