import React from 'react';
import { useFela, } from 'react-fela';
import PropTypes from 'prop-types';
import MastheadA11yMenu from './MastheadA11yMenu/MastheadA11yMenu';
import MastheadReadingList from './MastheadReadingList';
import MastheadSubscription from './MastheadSubscription';
import MastheadUserMenu from './MastheadUserMenu/MastheadUserMenu';
import { useEventTracker, } from '../../utils/EventTracker';
import GStat from '../Scripts/GStat';
import Scripts from '../Scripts/Scripts';
import useMedia from '../../hooks/useMedia';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import { useUser, } from '../User/UserDispenser';
import GET_USER_BY_MAIL from '../Account/queries/getUserByMail.graphql';
import Query from '../ApolloBoundary/Query';
import { getProductInfo, } from '../Account/helpers/subscription';


const { isHDC, } = checkSiteFromConfig();

const getUserName = (user = {}) => user.firstName || null;

const wrapperStyle = {
  alignItems: 'stretch',
  display: 'flex',
  marginStart: 'auto',
  height: '100%',
};

MastheadUserTools.propTypes = {
  themeColor: PropTypes.string,
};

MastheadUserTools.defaultProps = {
  themeColor: 'defaultTheme',
};

const subscriptionNames = {
  274: 'BOTH',
  243: 'paying_htz',
  273: 'paying_tm',
  282: 'INACTIVE',
  239: 'paying_hdc',
};


export default function MastheadUserTools({ menuItems, themeColor, }) {
  const { css, theme, } = useFela();
  const { user, } = useUser();
  const { biAction, } = useEventTracker();

  const className = css(wrapperStyle);
  // Logic when to show MastheadReadingList for **Registered** users.
  const renderSubscriptionButton = useMedia({ query: { from: 'xl', }, });
  const shouldDisplayMastheadHDC = user.type === 'paying' && isHDC;
  const shouldDisplayGift = shouldDisplayMastheadHDC;
  return (
    <Query
      query={GET_USER_BY_MAIL}
      skip={typeof document === 'undefined' || !user?.email}
      variables={{
        email: user?.email,
      }}
    >
      {({ loading, error, data, refetch, }) => {
        let isPartialSubscription;
        let userProductType;
        const defaultShowSubscriptionBtn = !user || user.type !== 'paying';
        let showSubscriptionBtn = defaultShowSubscriptionBtn;

        if (error || loading) {
          showSubscriptionBtn = defaultShowSubscriptionBtn;
          isPartialSubscription = false;
          userProductType = user.type;
        }
        else if (data?.userByMail) {
          const { ...personalInfo } = data?.userByMail || {};
          const { isApple, isAndroid, } = getProductInfo(
            (personalInfo || { products: [], }).products,
          );

          const prodNum = personalInfo?.products?.filter(product => {
            if (product.prodNum === 274 && product.status === 'SUBSCRIBED') {
              return true;
            } if ((product.prodNum === 243 && product.status === 'SUBSCRIBED')
          || (product.prodNum === 273 && product.status === 'SUBSCRIBED')) {
              return true;
            }
            return null;
          })?.[0]?.prodNum;

          userProductType = (prodNum && subscriptionNames[prodNum]) || user.type;
          isPartialSubscription = userProductType?.includes('paying_tm') || userProductType?.includes('paying_htz') || false;
          showSubscriptionBtn = (isPartialSubscription || (userProductType === 'anonymous') || (userProductType === 'registered')) && (!isApple || !isAndroid);
        }
        return (
          <div
            className={className}
            data-google-interstitial={false}
          >
            <React.Fragment>
              <MastheadUserMenu
                menuItems={menuItems}
                userType={user.type}
                userName={getUserName(user)}
                biAction={biAction}
                themeColor={themeColor}
              />
              {/* TODO: change gstat script location when user cache problem is fixed */}
              {user.anonymousId
                ? (
                  <Scripts scriptTag="gstat">
                    <GStat user={user} />
                  </Scripts>
                )
                : null
              }
              {showSubscriptionBtn || shouldDisplayGift
                ? <MastheadSubscription isSubscriptionGift={shouldDisplayGift} isPartialSubscription={isPartialSubscription} />
                : null}
              {theme.mobileReadingList
              && ((user.type === 'registered' && renderSubscriptionButton)
                || user.type === 'paying') ? (
                  <MastheadReadingList biAction={biAction} themeColor={themeColor} />
                ) : null}
            </React.Fragment>
            <MastheadA11yMenu themeColor={themeColor} />
          </div>
        );
      }}
    </Query>
  );
}
