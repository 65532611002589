import { useRouter, } from 'next/router';
import usePreview from './usePreview';

const useLiveBlogQueryVariables = articleId => {
  const router = useRouter();
  const { previewId, } = usePreview();

  const { asPath, query, } = router;

  if (!(articleId || previewId)) {
    return {};
  }

  const regex = /\/ty-article-live\//;
  const containsTyArticleLive = regex.test(asPath);
  const liveBlogItemId = query.liveBlogItemId;


  const queryVariables = {
    id: previewId || articleId,
  };

  if (containsTyArticleLive) {
    queryVariables.liveBlogItemsCount = liveBlogItemId ? 1 : 10;
    if (liveBlogItemId) {
      queryVariables.liveBlogItemId = liveBlogItemId;
    }
  }

  return queryVariables;
};

export default useLiveBlogQueryVariables;
